<template>
    <div class="affiliateAddressWrapper">
		<div class="affiliate-address-search-bar">
			<Search
				ref="affiliateAddressesSearch"
				@queryString="queryString"
				@filterObject="advanceSearch"
				@clearFilter="advanceSearch"
				:placeholder="placeholder"
				:isAdvanceSearch="true"
				:filterConfig="selectedSearchConfig"
			></Search>
		</div>

		<div v-if="showErrorMessage" class="content-error-message">
			{{ errorMessage }}
		</div>

		<div class="card-wrapper">
			<div v-for="address in affiliateAddressList" :key="address._id" class="card-element">
				<InformationCard :expandCard="false" :isCardDisabled="!address.record.active">
					<template v-slot:topLeft>
						<span class="branch-name">
							{{ address.branch_name }}
						</span>
					</template>
					<template v-slot:topRight>
						{{ address.zone }}
					</template>
					<template v-slot:moreInfo>
						{{ address.address }}<br />
						{{ address.state }}<br />
						{{ address.city }} - {{ address.pincode }}
					</template>
					<template v-slot:actionButtons>
						<template>
							<v-btn @click="openChangelogsModal(address)" icon color="secondary" text
								><v-icon>mdi-information-outline</v-icon></v-btn
							>
							<v-btn @click="disablePartner(address)" color="error" text>
								{{ address.record.active ? "Disable" : "Enable" }}
							</v-btn>
							<v-btn @click="openInputForm(true, address)" color="secondary" text>
								Edit
							</v-btn>
						</template>
					</template>
				</InformationCard>
			</div>
		</div>

		
		<ChangeLogModal
			@closeModal="toggleChangelogModal = false"
			:toggleChangelogModal="toggleChangelogModal"
			:selectedInfo="selectedCardInfo"
		></ChangeLogModal>

		<UserForm
			@formOutput="formOutput"
			@closeForm="closeForm"
			:name="name"
			:inputConfig="inputConfig"
			:toggleForm="toggleForm"
			:formData="rowToEdit"
			:isEditMode="isEditMode"
		></UserForm>

		<div class="floating-button">
			<v-btn @click="openInputForm()" color="primary" dark fab>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</div>
    </div>
</template>

<script>
	import defaultCRUDMixin from "../../mixins/defaultCRUDMixins";
	import inputFormMixin from "../../mixins/inputFormMixin";
	import searchMixin from "../../mixins/searchMixin";
	import { required, email, minLength, numeric, alpha } from "vuelidate/lib/validators";
	import { mapActions, mapGetters, mapMutations } from "vuex";
	import helpers from "../../components/helpers";
	import ChangeLogModal from "../../components/ChangeLog";


    export default{
        name: "AffiliatesAddress",
		mixins: [defaultCRUDMixin, inputFormMixin, searchMixin],
		props: {
			affiliateInfo: { required: true, type: Object },
		},
		components:{
			ChangeLogModal
		},
		async created() {
			this.getData();
			await this.getCities();
			await this.getStates();
			this.setInputConfig(this.statesList, this.citiesList);
			this.setSearchConfig(this.statesList);
		},
		// mounted() {
		// 	console.log(this.statesList)
		// },
		data: () => ({
			name: "Affiliate Address",
			placeholder: "Search Addresses",
			toggleChangelogModal: false,
			selectedCardInfo: {},
			activeState: true,
			affiliateAddressList: [],
			inputConfig: [],
			statesList:[],
			citiesList: [],
		}),
        computed: {
		},
        methods: {
            ...mapActions("Affiliates", [
				"getAffiliateAddresses",
				"addAffiliateAddress",
				"editAffiliateAddress",
				"getStatesList",
			]),
			...mapActions("FollowUp", ["getCitiesList"]),
			...mapMutations("Affiliates", ["setAffiliateAddressList"]),
			getStates() {
				return this.getStatesList({
					filter: {},
				}).then((data) => {
					this.statesList = data.list;
				});
			},
            advanceSearch(filterObject) {
				this.filter = { ...filterObject };
				if (this.filter.active) {
					this.activeState = false;
				} else {
					this.activeState = true;
				}
				this.pageNo = 1;
				this.getData();
			},
			getData(callMutation = false) {
				this.openLoaderDialog();
				this.filter.affiliate_id = this.affiliateInfo._id;
				this.filter.active = this.activeState;
				this.getAffiliateAddresses({
					filter: this.filter,
					affiliate_id: this.affiliateInfo._id,
					active: this.activeState,
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				}).then((data) => {
					this.closeLoaderDialog();
					console.log("address : ");
					console.log(data);
					this.affiliateAddressList = this.checkForErrorMessage(data, "address");
					// this.addressList = data.list;
					this.totalCount = data.totalCount;
					this.fetchCount = data.fetchCount;
					if (callMutation) {
						this.setAffiliateAddressList(this.setAffiliateAddressList);
					}
				});
			},
			getCities() {
			return this.getCitiesList({
				filter: {},
			}).then((data) => {
				this.citiesList = data.list;
			});
			},
			setInputConfig(statesList = [], citiesList = []) {
				this.inputConfig = [
					{
						name: "Branch Name*",
						type: "String",
						key: "branch_name",
						width: "half",
						validations: {
							required,
							minLength: minLength(1),
						},
					},
					{
						name: "Address*",
						type: "String",
						key: "address",
						width: "half",
						validations: {
							required,
							minLength: minLength(1),
						},
					},
					{
						name: "Zone*",
						type: "Dropdown",
						key: "zone",
						width: "half",
						multi: false,
						isListInStore: true,
						listVariable: "zone",
						validations: {
							required,
						},
					},
					{
						name: "State*",
						type: "Dropdown",
						key: "state",
						width: "half",
						multi: false,
						isListInStore: false,
						listItems: statesList,
						validations: {
							required,
						},
					},
					{
						name: "City*",
						type: "Dropdown",
						key: "city",
						width: "half",
						multi: false,
						isListInStore: false,
						listItems: citiesList,
						validations: {
							required,
						},
					},
					{
						name: "Pincode*",
						type: "Number",
						key: "pincode",
						width: "half",
						validations: {
							required,
							numeric,
						},
					},
				];
			},
			setSearchConfig(statesList = []) {
				this.selectedSearchConfig = [
					{
						name: "Branch Name",
						key: "branch_name",
						type: "text",
						inputType: "textfield",
						defaultValue: "",
					},
					{
						name: "Zone",
						key: "zones",
						multi: true,
						inputType: "dropdown",
						defaultValue: [],
						isListInStore: true,
						listVariable: "zone",
					},
					{
						name: "State",
						key: "states",
						multi: true,
						inputType: "dropdown",
						defaultValue: [],
						isListInStore: false,
						listItems: statesList,
					},
					{
						name: "Show Disabled Users",
						key: "active",
						inputType: "switch",
						defaultValue: false,
					},
				];
			},
			async formOutput(data) {
				var formData = JSON.parse(JSON.stringify(data));
				formData.affiliate_id = this.affiliateInfo._id;
				formData.affiliate_address_id = this.affiliateInfo._id;

				this.openLoaderDialog();
				if (!this.isEditMode) {
					this.addAffiliateAddress(formData).then((data) => {
						this.closeLoaderDialog();
						if (data.ok) {
							console.log(data)
							this.openSnackbar({ text: "Sucessfully Added Address" });
							this.getData(true);
							this.closeForm();
						} else {
							this.openSnackbar({ text: data.message });
						}
					});
				} else {
					this.editAffiliateAddress(formData).then((data) => {
						this.closeLoaderDialog();
						if (data.ok) {
							this.openSnackbar({ text: "Sucessfully Edited Address" });
							this.getData(true);
							this.closeForm();
						} else {
							this.openSnackbar({ text: data.message });
						}
					});
				}
			},
			getEditRowObject(data) {
				return {
					...data,
					_id: data._id,
					updated_on: data.record.updated_on,
				};
			},
			disablePartner(data) {
				if (
					window.confirm(
						"Do you really want to " + (data.record.active ? "Disable the Address?" : "Enable the Address?")
					)
				) {
					this.openLoaderDialog();
					this.editAffiliateAddress({
						_id: data._id,
						active: !data.record.active,
						updated_on: data.record.updated_on,
						status: data.record.active ? "disabled" : "enabled",
					}).then((data) => {
						this.closeLoaderDialog();
						if (data.ok) {
							this.openSnackbar({ text: "Sucessfully Updated Address Status" });
							this.getData();
							this.closeForm();
						} else {
							this.openSnackbar({ text: data.message });
						}
					});
				}
			},
			openChangelogsModal(info) {
				console.log(info)
				// this.getChangelogs(info);
				this.selectedCardInfo = { ...info };
				this.toggleChangelogModal = true;
			},
        },
		watch: {
			affiliateInfo: {
				deep: true,
				async handler(nv, ov) {
					this.filter = {};
					this.affiliateAddressList = [];
					this.pageNo = 1;
					await this.getStates();
					this.$refs.affiliateAddressesSearch.clearFilters(false);
					this.setInputConfig(this.storeStatesList, this.citiesList);
					this.setSearchConfig(this.storeStatesList);
					console.log("Address watcher")
				},
			},
		},
    }

</script>

<style lang="scss" scopped>
	.affiliateAddressWrapper {
		padding: 20px 5px;
		height: 100%;

		.branch-name {
			text-transform: none;
		}
	}
	.affiliate-address-search-bar {
		margin-top: 12px;
		display: flex;
		justify-content: center;
	}
</style>