<template>
	<div class="affiliateInfoWrapper">
		<v-tabs grow v-model="tab">
			<v-tab>Addresses</v-tab>
			<v-tab>Employees</v-tab>
		</v-tabs>
		<v-tabs-items class="tabItemWrapper" v-model="tab">
			<v-tab-item>
				<AffiliateAddresses :affiliateInfo="affiliateInfo"></AffiliateAddresses>
			</v-tab-item>
			<v-tab-item>
				<AffiliateEmployees :affiliateInfo="affiliateInfo"></AffiliateEmployees>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapMutations } from "vuex";
	import AffiliateAddresses from "./AffiliateAddresses";
	import AffiliateEmployees from "./AffiliateEmployees";

	export default {
		name: "AffiliateInfo",
		components: {
			AffiliateAddresses,
			AffiliateEmployees,
		},
		created() {
			// this.getPartnerList();
		},
		data: () => ({
			tab: "",
		}),
		computed: {
			...mapGetters([]),
		},
		methods: {
			...mapActions([]),
		},
		props: {
			affiliateInfo: { required: true, type: Object },
		},
	};
</script>

<style lang="scss">
	.affiliateInfoWrapper {
		height: 100%;
		.tabItemWrapper {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}
</style>
